@import "index";

@media screen {
  body {
    font-size: 1.08em;
    margin: 0 0 0 16.5em;
    max-width: inherit;
    overscroll-behavior: none; }

  body > article, body > footer {
    margin: 0 5em;
    padding: 2em 0;
    max-width: 50em; }

  body > header {
    display: block;
    position: fixed;
    overflow: hidden;
    background: darken($dark, 2%);
    color: #fff;
    border: none;
    box-shadow: none;
    width: 16.5em;
    opacity: .98;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0; }

  article header h1 {
    font-size: 2em; }

  nav {
    display: block;
    margin: 0; }

  nav a {
    display: block !important;
    font-size: 1.25em;
    margin: 0;
    padding: .8em 2.25em;
    outline: none; }

  nav a:hover, nav a.selected {
    background: lighten($dark, 2%);
    text-decoration: inherit;
    display: block !important;
    cursor: pointer;
    font-weight: bold; }

  article:not(:last-child) {
    border-bottom-color: reduce(20); }

  .icons svg {
    margin-right: 1.2em; }

  .hidden {
    display: inline-block; }



  .title {
    display: none; }





  .profile-picture {
    margin: 0;
    width: 100%;
    height: 300px;
    background-image: url('/assets/images/anthony-del-rosario.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: -18px; }

  .sidebar-footer {
    font-size: 1.25em;
    padding: .8em 2.25em;
    margin-top: 0.8em; } }
